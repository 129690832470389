<template>
      <div class="chinaMap">
        <div class="angle1"></div>
        <div class="angle2"></div>
        <div class="angle3"></div>
        <div class="angle4"></div>
      </div>

</template>

<script>
// import echarts from "echarts";
import "echarts/map/js/province/hunan.js"

export default {
  // props:['seriesData'],
  name: "",
  data(){
    return{
      seriesData:[],

    }
  },
  created() {
  },
  mounted() {
    this.setMap();
  },
  watch:{
    // seriesData.data.list[params.value]: {
    //   handler(value) {
    //     console.log(value,'value')
    //     // this.setMap();
    //   },
    //   immediate: true
    // }
  },
  methods:{
    setMap() {
      // console.log(this.seriesData,'chinamap-seriesData')
      let options = {
        title: {
          text: '',
          subtext: '',
          x: 'center'
        },
        tooltip: {
          // position: ['-50%', '-40%'],
          trigger: 'item',
          confine: true, //将tooltip限制在盒子内
          backgroundColor:'rgb(2,37,101)',
          borderRadius:'5px',
          formatter:function (params) {
            var toolTiphtml = ''
            // var i = this.chooseIndex;
            toolTiphtml+=`<div style="font-size: 20px;margin: 8px 15px;font-weight: 600;">${params.name}(项目信息)</div>`
            // for(var i = 0;i<params.data.list.length;i++){
              toolTiphtml+=`<div style="color: #fff;font-weight: 500;font-size: 18px;font-family:'宋体','Times New Roman',Times,serif;margin: 15px 5px;">
                                [${params.data.list[params.data.choiceIndex].title}]：
                            </div>
                           <div style="color: #fff;font-size: 16px;margin-bottom:30px;font-family:'华文细黑',Courier New,Courier,monospace;margin: 5px 15px;">
                            <div style="margin-top: 6px;">活动场次：<span style="color:rgb(252,221,45);font-size: 17px!important;font-weight: 500;">${params.data.list[params.data.choiceIndex].soprtcount}</span></div>
                             <div style="margin-top: 6px;">服务人次：<span style="color:rgb(252,221,45);font-size: 17px!important;font-weight: 500;">${params.data.list[params.data.choiceIndex].serviceCount}</span></div>
                             <div style="margin-top: 6px;">志愿者人数：<span style="color:rgb(252,221,45);font-size: 17px!important;font-weight: 500;">${params.data.list[params.data.choiceIndex].volunteerCount}</span></div>
                             <div style="margin-top: 6px;">覆盖学校数量：<span style="color:rgb(252,221,45);font-size: 17px!important;font-weight: 500;">${params.data.list[params.data.choiceIndex].schoolCount}</span></div>
                             <div style="margin-top: 6px;">投入款物（万元）：<span style="color:rgb(252,221,45);font-size: 17px!important;font-weight: 500;">${params.data.list[params.data.choiceIndex].money}</span></div>
                             <div style="margin-top: 6px;">社会组织参与数：<span style="color:rgb(252,221,45);font-size: 17px!important;font-weight: 500;">${params.data.list[params.data.choiceIndex].attent}</span></div>
                            </div>`
            // }
            return toolTiphtml;
          }
        },
        legend: {
          orient: 'vertical',
          x: 'left',
          data: ['']
        },
        // dataRange: {
        //   min: 0,
        //   max: 1000,
        //   x: 'left',
        //   y: 'bottom',
        //   text: ['max', 'min'],
        //   calculable: true
        // },
        toolbox: {
          show: false,
          orient: 'vertical',
          x: 'right',
          y: 'center',
          feature: {
            mark: {
              show: true
            },
            dataView: {
              show: true,
              readOnly: false
            },
            restore: {
              show: true
            },
            saveAsImage: {
              show: true
            }
          }
        },
        roamController: {
          show: true,
          x: 'right',
          mapTypeControl: {
            'china': true
          }
        },
        dataRange: { //左下角的颜色块。start：值域开始值；end：值域结束值；label：图例名称；color：自定义颜色值
          x: 'left',
          y: 'bottom',
          show:false,
          splitList: [
            {start: 0.1, label: '有数据', color: '#1a50af'},
            {start:0, end: 0,label: '无数据', color: '#3d60b4'}
          ]
        },
        series: [{
          name: '',
          type: 'map',
          mapType: '湖南',
          roam: false,
          zoom:1.2,
          showLegendSymbol:false,
          label: {
            normal: {
              show: true,
              color:'#fff',
              fontStyle:'italic',
              fontWeight:'bold',
              verticalAlign:'bottom',
              // offset:[30,40],
              fontSize:16
          }
          },
          itemStyle: {
            normal: {
              borderColor: '#26deee', //区域边框颜色
              areaColor: '#2452a9', //区域填充颜色
            },
            emphasis: {
              areaColor: 'rgb(57,173,236)',
              borderWidth:2,
            }
          },
          data:this.seriesData
        }],

      }

      let myChart = this.$echarts(this.$el);

      // myChart.clear();
      // myChart.resize()
      myChart.setOption(options);
    },

  }
}
</script>

<style lang="less" scoped>
.chinaMap {
    width:100%;
    margin-top: 3%;
    height:94%;
  border: 1px solid #0D2451;
  position: relative;
  .angle1{
    top: 0;
    left: 0;
    display: inline-block;
    position: absolute;
    width: 10px;
    height: 10px;
    border-top: 1px solid #1C5AB3;
    border-left: 1px solid #1C5AB3;
  }
  .angle2{
    display: inline-block;
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0;
    right: 0;
    border-top: 1px solid #1C5AB3;
    border-right: 1px solid #1C5AB3;
  }
  .angle3{
    display: inline-block;
    position: absolute;
    width: 10px;
    height: 10px;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid #1C5AB3;
    border-left: 1px solid #1C5AB3;
  }
  .angle4{
    display: inline-block;
    position: absolute;
    width: 10px;
    height: 10px;
    bottom: 0;
    right: 0;
    border-bottom: 1px solid #1C5AB3;
    border-right: 1px solid #1C5AB3;
  }
}

</style>